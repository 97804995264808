<template>
  <div class="checkBoxWrap">
    <label>
      <div class="checkBoxHint">
        <input type="checkbox"
               :true-value="trueValue"
               :false-value="falseValue"

               v-on="inputListeners"
        >
        <div class="visualCheckBox" v-bind:class="{active:isTrue}">
          <svg viewBox="0 0 12.828 8.414" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.414 1.414l-6.251 6-3.749-3.6" fill="none" stroke="rgba(13, 87, 64, 1)" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/></svg>
        </div>
      </div>
      <p v-html="label"></p>
    </label>
  </div>

</template>

<script>

  export default {
    name: "fmCheckBox",
    data(){
      return {
        value:true,
      }
    },
    computed:{
      inputListeners: function () {
        var vm = this;
        // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
        return Object.assign({},
          // Мы добавляем все слушатели из родителя
          this.$listeners,
          // Затем мы можем добавить собственные слушатели или
          // перезаписать поведение некоторых существующих.
          {
            // Это обеспечит, что будет работать v-model на компоненте
            input: function (event) {
              vm.value = event.target.checked;
              vm.$emit('input', event.target.checked)
            }
          }
        )
      },
      isTrue(){
        return this.value == this.trueValue;
      },
    },
    props:{
      label:{
        type:String,
        default:'',
      },
      trueValue:{
        type:Boolean,
        default: true
      },
      falseValue:{
        type:Boolean,
        default: false
      },
    }
  }
</script>

<style scoped>
.checkBoxWrap{position: relative;background: rgba(255, 0, 0, 0.0);transition: 0.4s ease-out;}
.checkBoxWrap.fail{background: rgba(255, 0, 0, 0.5);}
label{display: flex;align-items: center;justify-content: center;cursor: pointer;}
label input{opacity: 0}
label p{text-align: left}
.visualCheckBox{width: 5vw;height: 5vw;display: flex;align-items:
  center;justify-content: center;width: 6vw;height: 6vw;border-radius: 2px;background: white;border: 1px solid #c2c2c2;}
.visualCheckBox svg{width: 3.2vw;height: 1.9vw;opacity: 0;stroke: #43AC35;stroke-width: 2}
.visualCheckBox.active svg{opacity: 1;}
.checkBoxHint{position: relative;width: 13vw;height: 6vw;}
.checkBoxHint > * {position: absolute;top:0;left:0}

@media screen and (min-width: 760px) {

.visualCheckBox, .checkBoxHint{width: 1.6vw;height: 1.6vw;}
  .checkBoxHint{margin-right: 1vw}
  .visualCheckBox svg{    width: 0.9vw;height: 0.6vw;}
}
@media screen and (min-width: 1140px) {
  .visualCheckBox, .checkBoxHint{width: 18px;height: 18px;}
  .checkBoxHint{margin-right: 10px;}
  .visualCheckBox svg{width: 14px;height: 8px;}
}
</style>
